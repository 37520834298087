import './App.css';
import { Resume } from './resume/Resume';

function App() {
  return (
    <Resume />
  );
}

export default App;
