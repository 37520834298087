import { LabelledInfo } from "../components/LabeledInfo"


export const ResumeDetailsBar = () => {

   return (
      <div className="resumeDetailsBar">
         <LabelledInfo title="Location" value="Salt Lake City, Utah" />
         <LabelledInfo title="Education" value="BS In Information Systems @ SUU (2009)" />
         <LabelledInfo title="Languages" value="English, Portuguese" />
      </div>
   )
}
