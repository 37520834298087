

type Props = {
   title: string;
   value: string;
}

export const LabelledInfo = ({
   title,
   value
}: Props) => {
   return (
      <span className="labelledInfo">
         <small>{title}</small>
         <span>{value}</span>
      </span>
   )
}
