import { useEffect, useState } from "react"


export const ContactInfo = () => {


   const [emailAddressPrintOnly, setEmailAddressPrintOnly] = useState<string>("")
   useEffect(() => {

      const beforePrint = () => {
         console.log('Functionality to run before printing.')
         setEmailAddressPrintOnly(process.env.REACT_APP_CONTACT_EMAIL || "")
      }
      window.addEventListener('beforeprint', beforePrint)

      const afterPrint = () => {
         console.log('Functionality to run after printing')
         setEmailAddressPrintOnly("")
      }
      window.addEventListener('afterprint', afterPrint)

      return () => {
         window.removeEventListener('beforeprint', beforePrint)
         window.removeEventListener('afterprint', afterPrint)
      }

   }, [])


   return (
      <div className="contactInfo">
         <div>
            <a href="https://www.linkedin.com/in/todd-b-fisher/">
               <small>
                  linkedin.com/in/
               </small>
               <span>
                  todd-b-fisher
               </span>
            </a>
         </div>
         <div>{emailAddressPrintOnly}</div>
      </div>
   )
}
