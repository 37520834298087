import { ContactInfo } from "./ContactInfo"


export const ResumeHeader = () => {


   return (
      <div className="resumeHeader">
         <div>
            <h1>
               Todd B Fisher
            </h1>
            <ContactInfo />
         </div>

         <div className="professionalSummary">
            Over 15 years of experience in software engineering and
            leadership, with a strong focus on fostering
            a healthy work culture that drives sustainable business value
         </div>

      </div>
   )
}
