import { Experience } from "./Experience"
import { resumeData } from "./ResumeData"
import { ResumeDetailsBar } from "./ResumeDetailsBar"
import { ResumeHeader } from "./ResumeHeader"


export const Resume = () => {


   return (
      <div className="resume">

         <ResumeHeader />

         <ResumeDetailsBar />

         <div>
            {resumeData.experience.map((experienceEntry) =>
               <Experience data={experienceEntry} />
            )}
         </div>

      </div>
   )
}
