
import { ReactNode } from "react";


export type ExperienceEntry = {
   title: string;
   company: string;
   timeRange: string;
   years: number;
   months: number;
   summaryHtml: ReactNode;
   isCompact?: boolean;
}

type ResumeData = {
   experience: ExperienceEntry[];
}


export const resumeData: ResumeData = {
   experience: [
      {
         title: "Chief Technology Officer",
         company: "Verdi Ai",
         timeRange: "Feb 2023 - July 2024",
         years: 1,
         months: 6,
         // startDate: new Date("2023-02-01"),
         // endDate: new Date("2024-07-15"),
         summaryHtml: (
            <>
               <ul>
                  <li>Built an AI driven SaaS product to help businesses make better decisions, targeting the product management space.</li>
               </ul>
               <small>
                  Startup, engineering, product management, strategy, OpenAI<br />
               </small>
            </>
         )
      },
      {
         title: "Principal of Software Engineering Practices",
         company: "Pluralsight",
         timeRange: "Apr 2021 - Feb 2023",
         years: 1,
         months: 11,
         // startDate: new Date("2021-04-01"),
         // endDate: new Date("2023-02-01"),
         summaryHtml: (
            <>
               <ul>
                  <li>Led cross org initiatives to further unify engineering principles & practices, security compliance, and engineering KPIs.</li>
                  <li>Created and taught engineering onboarding curriculum to new hires worldwide.</li>
                  <li>Actively promoted our engineering culture by organizing hack-a-thons, mob sessions, knowledge sharing, retros and individual check-ins.</li>
               </ul>
               <small>
                  1:1s with engineering leaders, developer experience, cross functional teams, value delivery
               </small>
            </>
         )
      },
      {
         title: "Acting VP, Technology Center of Excellence",
         company: "Pluralsight",
         timeRange: "Jan 2022 - May 2022",
         years: 0,
         months: 5,
         // startDate: new Date("2022-01-01"),
         // endDate: new Date("2022-05-01"),
         summaryHtml: (
            <>
               <ul>
                  <li>Supported my team in efforts around accessibility, design ops, skilling-up and engineering practices, which included:</li>
                  <ul>
                     <li>Delivering internal training on strategic topics such as Terraform and accessibility, via Pluralsight's own products.</li>
                     <li>Rolling out a design system for internal Pluralsight teams.</li>
                  </ul>
               </ul>
               <small>
                  Reported directly to the CTO, cross-org initiatives
               </small>
            </>
         )
      },
      {
         title: "Principal Software Engineer",
         company: "Pluralsight",
         timeRange: "Aug 2019 - Apr 2021",
         years: 1,
         months: 9,
         // startDate: new Date("2019-08-01"),
         // endDate: new Date("2021-04-01"),
         summaryHtml: (
            <>
               <ul>
                  <li>Mentored engineers and contributed to team initiatives.</li>
                  <li>Refined and participated in the interview process for engineers.</li>
                  <li>Ran the engineering side of the Internship/Apprentice programs (3 yrs).</li>
               </ul>
               <small>
                  1:1s with engineers, career development, mass interviews, training
               </small>
            </>
         )
      },
      {
         title: "Senior Software Engineer",
         company: "Pluralsight",
         timeRange: "Oct 2018 - Aug 2019",
         years: 0,
         months: 11,
         // startDate: new Date("2018-10-01"),
         // endDate: new Date("2019-08-01"),
         summaryHtml: (
            <>
               <ul>
                  <li>Developed scalable, user targeted communication tools for other teams.</li>
                  <li>Improved the user Profile by adding daily activity tracking, learning streaks and content insights.</li>
               </ul>
               <small>
                  AWS, .Net Core (C#), Node, Postgres, React, Typescript, TDD, paired programming, CI/CD
               </small>
            </>
         )
      },
      {
         title: "Lead Software Engineer / Director / VP of Software Engineering",
         company: "TouchMD",
         timeRange: "2009 - 2018",
         years: 9,
         months: 4,
         // startDate: new Date("2009-07-01"),
         // endDate: new Date("2018-10-01"),
         isCompact: true,
         summaryHtml: (
            <>
               <ul>
                  <li>Led the technical evolution of TouchMD, from early startup to a well established product.</li>
                  <li>Recruited, managed and worked alongside the engineering teams.</li>
                  <li>Built cross-platform, multilingual content management systems.</li>
               </ul>
               <small>
                  Multiple hats, 3rd party integrations, HIPAA, security, privacy, contracts,
                  Azure, .Net (C#), AngularJS, SQL Server, Windows, Android, iOS
               </small>
            </>
         )
      },
      {
         title: "Adjunct Professor",
         company: "Southern Utah University",
         timeRange: "2012 - 2016",
         years: 3,
         months: 9,
         // startDate: new Date("2012-08-01"),
         // endDate: new Date("2016-04-01"),
         isCompact: true,
         summaryHtml: (
            <>
               <ul>
                  <li>Taught a web development course using HTML, CSS, Javascript</li>
                  <li>Taught a course where students launched their own e-commerce site.</li>
                  {/* <li>Taught a basic computer skills course</li> */}
               </ul>
               <small>
                  Curriculum development, presenting, hands-on, mentoring
               </small>
            </>
         )
      },
      {
         title: "Freelance Web Developer",
         company: "",
         timeRange: "2005 - 2013",
         years: 8,
         months: 0,
         // startDate: new Date("2005-01-01"),
         // endDate: new Date("2013-01-01"),
         isCompact: true,
         summaryHtml: (
            <>
               <ul>
                  <li>Built and managed various web development projects for clients</li>
               </ul>
               <small>
                  HTML, CSS, JavaScript, mySQL, jQuery, Photoshop, Wordpress, Joomla, Flash/ActionScript 3, PHP
               </small>
            </>
         )
      },
      {
         title: "IT Technical Support",
         company: "Southern Utah University",
         timeRange: "2006 - 2009",
         years: 3,
         months: 6,
         // startDate: new Date("2006-01-01"),
         // endDate: new Date("2009-06-01"),
         isCompact: true,
         summaryHtml: (
            <>
               <ul>
                  <li>Maintained Computer systems for Administrative Departments and helped users with their problems.</li>
               </ul>
               <small>Windows, networking, printers, malware removal, web browsers,
                  Microsoft Office, Adobe Creative Suite, mobile technologies
               </small>
            </>
         )
      }
   ]
}
