import { ExperienceEntry } from "./ResumeData"


type Props = {
   data: ExperienceEntry;
}

export const Experience = ({
   data
}: Props) => {

   const { title, company, timeRange, years, months, summaryHtml, isCompact } = data;

   const yearLabel = years === 1 ? "year" : "years";
   const monthLabel = months === 1 ? "month" : "months";

   return (
      <div className="experienceEntry">
         <div>
            <h2>
               {title}
            </h2>
            <h3>
               <span>
                  {company}
               </span>
               <span className="totalTime">
                  {years > 0 &&
                     <>
                        <span>{years} </span>
                        <small>{yearLabel}</small>
                     </>

                  }
                  {months > 0 &&
                     <>
                        <span> {months} </span>
                        <small>{monthLabel}</small>
                     </>
                  }
                  {isCompact
                     ? <small>({timeRange})</small>
                     : <br />
                  }
               </span>
               {!isCompact &&
                  <small>({timeRange})</small>
               }
            </h3>
         </div>
         <div className="experienceSummary">
            {summaryHtml}
         </div>
      </div>
   )

}
